.contacts-section-textarea textarea {
    height: 40px;
}
.section-05-contleft,.section-05-contright {
    width: 50%;

    .complex_header {
        color: #2f4559;
        padding-top: 0px;
        padding-bottom: 10px;
        font-family: Subaru-Light;
        max-height: 70px;
        font-weight: normal;
        font-size: 21px;
        line-height: 21px;
        letter-spacing: 0px;
    }
}
.contacts-formslist {
    display: block;
}
.contacts-section-input11 input, .contacts-section-input2 input, .contacts-section-input input, .contacts-section-textarea textarea {
    width: 100%;
}
.sect-05-ouradress, .contactsl-title {
    font-size: 14px;
}
.sect-05-ouradress {
    .contactsl-title {
        text-transform: none;
        font-size: 16px;
        text-decoration: underline;
        color: black;
    }
    .contacts-adress {
        font-size: 14px;
        margin-left: 15px;
    }
    div, .contacts-adress {
        color: #626262;
    }
}
.contactsl-info {
    display: flex;
    margin-bottom: 10px;

    span:first-child {
        margin-right: 10px;
    }
}

.footer {
    margin-top: 50px;
}
